<template>
  <v-container class="contenedor-tools">
    <!-- botones EXTRAS  -->
    <v-container
      v-for="b in btns"
      v-bind:key="b.name"
      class="contenedor-tools__container-btn"
    >
      <v-btn
        class="contenedor-tools__btn"
        small
        color="primary"
        @click="change(b.evento)"
      >
        {{ b.name }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "ToolBarNavigationBtns",
  props: {
    btns: Array,
  },
  watch: {},
  data() {
    return {
      Action: "",
    };
  },
  mounted() {},
  methods: {
    change(a) {
      this.$emit("toggle", a);
    },
  },
};
</script>

<style scoped>
.contenedor-tools {
  margin: 0;
  width: auto;
  display: flex;
  padding: 5px;
  /* border: 1px #8884 solid; */
}

.contenedor-tools__btn {
  margin: 0;
  text-transform: none;
}

.contenedor-tools__container-btn {
  width: auto;
  margin-right: 2%;
  padding: 0;
  background-color: aquamarine;
}
</style>