<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav-btns
        v-on:toggle="eventosBtns($event)"
        :btns="[
          { name: 'Cliente', evento: 'crear cliente' },
          { name: 'Cliente trabajo', evento: 'crear cliente trabajo' },
        ]"
      >
      </tool-bar-nav-btns>
      <tool-bar-nav
        :pageRow="{ value: pageLengthPer, type: 'clientesAHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'editar_lista_admin',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :deleteBottom="{
          badgeText: 'Eliminar cliente',
          tipoEndpoint: 'cliente',
          endpoint: '/eliminaciones',
          atributo: 'estudianteId',
        }"
        :editRowBottom="{
          badgeText: 'Editar cliente',
          tipo: 'editCli',
        }"
        :copyRowBottom="{ badgeText: 'Copiar cliente' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          nameDoc: 'Clientes',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: adminLevel != 1 ? $refs.grid1 : $refs.grid }"
        v-on:toggle="eventosClientesAdmin($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container
        class="contenedor-tabla"
        v-show="mostrarTable == true && adminLevel != 1"
      >
        <vue-excel-editor
          ref="grid1"
          v-if="mt"
          v-show="mostrarTable == true && adminLevel != 1"
          @select="onSelect"
          v-model="jsondata"
          filter-row
          :page="pageLengthPer"
          :localized-label="myLabels"
          :cell-style="metodoColorBack"
          remember
          :enterToSouth="enterAbajo"
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
        >
          <vue-excel-column
            field="Nombre"
            label="Nombre"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Cédula"
            label="Cédula"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Linea"
            label="Linea"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono 2"
            label="Teléfono 2"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono"
            label="Teléfono"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Usuario"
            label="Usuario"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Contraseña"
            label="Contraseña"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="CEAD"
            label="CEAD"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Carrera"
            label="Carrera"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Edad"
            label="Edad"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Género"
            label="Género"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="País"
            label="País"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Servidor"
            label="Servidor"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Moodle"
            label="Moodle"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Contraseña correo"
            label="Contraseña correo"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Lista negra"
            label="Lista negra"
            type="string"
          />
          <vue-excel-column
            field="LINK DRIVE"
            label="LINK DRIVE"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            fiels="Agregar ventas"
            label="Agregar ventas"
            :change="openVenta"
            type="select"
            :options="['Agregar ventas', 'Agregar trabajo']"
          />
          <vue-excel-column field="Activo" label="Activo" type="string" />
        </vue-excel-editor>
      </v-container>
      <v-container v-show="mostrarTable == true && adminLevel == 1">
        <vue-excel-editor
          ref="grid"
          v-if="mt"
          v-show="mostrarTable == true && adminLevel == 1"
          v-model="jsondata"
          :page="pageLengthPer"
          filter-row
          :localized-label="myLabels"
          remember
          free-select
          :enterToSouth="enterAbajo"
          v-on:toggle-table="activeRigthClick($event)"
          :cell-style="metodoColorBack"
          @cell-focus="getLabelColor"
        >
          <!-- <vue-excel-column field="p300"   label="300p"   type="map" :options="{true: 'SI', false: 'NO'}"  :validate="valid" /> -->
          <vue-excel-column
            field="Nombre"
            label="Nombre"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Cédula"
            label="Cédula"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono"
            label="Teléfono"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono 2"
            label="Teléfono 2"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Usuario"
            label="Usuario"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Contraseña"
            label="Contraseña"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="CEAD"
            label="CEAD"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Carrera"
            label="Carrera"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Edad"
            label="Edad"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Género"
            label="Género"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="País"
            label="País"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Servidor"
            label="Servidor"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Moodle"
            label="Moodle"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Linea"
            label="Linea"
            type="number"
            :validate="valid"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Contraseña correo"
            label="Contraseña correo"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Lista negra"
            label="Lista negra"
            type="string"
          />
          <vue-excel-column field="Activo" label="Activo" type="string" />
        </vue-excel-editor>
      </v-container>
      <template>
        <!-- Dialogo de popup trabajo -->
        <v-dialog v-model="infoP" v-if="infoP" max-width="40%">
          <info-p
            v-on:toggle="toggleMSJ($event)"
            :title="'Ten en cuenta al crear la venta del trabajo'"
            :text="mensaje"
          ></info-p>
        </v-dialog>
        <v-dialog persistent v-if="addCli" v-model="addCli" max-width="90%">
          <new-client
            v-on:toggle="eventosCliente($event)"
            :info="this.onSelected == null ? '' : this.onSelected.field"
          >
          </new-client>
        </v-dialog>
        <!-- nuevo cliente trabajo -->
        <v-dialog persistent v-if="addCliT" v-model="addCliT" max-width="90%">
          <new-client-t
            v-on:toggle="eventosClienteT($event)"
            :info="this.onSelected == null ? '' : this.onSelected.field"
          ></new-client-t>
        </v-dialog>
        <v-dialog v-model="addVenta" v-if="addVenta" max-width="80%">
          <venta-view
            :title="'Venta'"
            :nameCard="nameCard"
            :idCli="idCli"
            :linkDrive="linkDrive"
            :info="null"
            v-on:toggle="eventosVenta($event)"
          ></venta-view>
        </v-dialog>
        <!-- Venta trabajo  -->
        <v-dialog
          persistent
          v-model="addVentaTrabajo"
          v-if="addVentaTrabajo"
          max-width="80%"
        >
          <venta-trabajo-view
            :title="'Trabajo'"
            :nameCard="nameCard"
            :linkDrive="linkDrive"
            :info="null"
            :idCli="idCli"
            v-on:toggle="eventosVenta($event)"
          ></venta-trabajo-view>
        </v-dialog>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import InfoP from "./component/infoP.vue";
// import VentaView from "./component/VentaView.vue";
// import NewClient from "./component/NewClient.vue";
// import VentaTrabajoView from "./component/VentaTrabajoView.vue";
import ToolBarNavBtns from "./component/ToolBarNavBtns.vue";
import ToolBarNav from "./component/ToolBarNav.vue";
import infoSelected from "./Tools/itemSelected";
// import NewClientT from "./component/NewClientT.vue";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNavBtns,
    ToolBarNav,
    NewClient: () =>
      import(/* webpackChunkName: "NewClient"  */ "./component/NewClient.vue"),
    NewClientT: () =>
      import(
        /* webpackChunkName: "NewClientT"  */ "./component/NewClientT.vue"
      ),
    InfoP: () =>
      import(/* webpackChunkName: "InfoP"  */ "./component/infoP.vue"),
    VentaView: () =>
      import(/* webpackChunkName: "VentaView"  */ "./component/VentaView.vue"),
    VentaTrabajoView: () =>
      import(
        /* webpackChunkName: "VentaTrabajoView"  */ "./component/VentaTrabajoView.vue"
      ),
    // NewClient,
    // NewClientT,
    // InfoP,
    // VentaView,
    // VentaTrabajoView,
  },

  data() {
    return {
      mt: false,
      textoActionToolBar: [],
      guardando: false,
      dialog: false,
      materias: "Ver materias",
      jsondata: [],
      addCli: false,
      addCliT: false,
      addVenta: false,
      addVentaTrabajo: false,
      listaColumnas: [],
      columnasMostrar: [],
      mostrarTable: false,
      editarColumnas: false,
      idCli: null,
      activo: [
        { data: true, value: "SI" },
        { data: false, value: "NO" },
      ],
      nameCard: null,
      adminLevel: null,
      selectedRow: [],
      onSelected: null,
      infoP: false,
      mensaje: [],
      linkDrive: null,
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
      pageLengthPer: this.$store.state.clientesHeigth,
    };
  },

  async created() {
    document.title = "Clientes";
    this.adminLevel = this.$store.state.adminLevel;
    await this.cargueColors();
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.jsondata = [];
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.mt = false;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/listaEstudiantes", fd).then((result) => {
            let data = result.data.lista;
            // console.log(data[5])
            for (var i = 0; i < data.length; i++) {
              data[i]["Usuario"] =
                data[i]["Usuario"] == "null" ? "-" : data[i]["Usuario"];
              data[i]["Contraseña"] =
                data[i]["Contraseña"] == "null" ? "-" : data[i]["Contraseña"];
              data[i]["Contraseña correo"] =
                data[i]["Contraseña correo"] == "null"
                  ? "-"
                  : data[i]["Contraseña correo"];
              data[i]["CEAD"] =
                data[i]["CEAD"] == "null" ? "-" : data[i]["CEAD"];
              data[i]["Carrera"] =
                data[i]["Carrera"] == "null" ? "-" : data[i]["Carrera"];
              data[i]["Activo"] =
                data[i]["Activo"] == true ? "Activo" : "Inactivo";
              data[i]["Linea"] =
                data[i]["Linea"] == "null"
                  ? "-"
                  : data[i]["Linea"] == "undefined"
                  ? "-"
                  : data[i]["Linea"];
              data[i]["LINK DRIVE"] == "null" ? "" : data[i]["LINK DRIVE"];
            }
            this.jsondata = data;
            if (this.jsondata.length == 0) {
              this.$alert("No existen clientes creados");
            } else {
              this.mostrarTable = true;
              this.listaColumnas = result.data.visibilidad;
              for (var k = 0; k < this.listaColumnas.length; k++) {
                if (this.listaColumnas[k]["valor"] == true) {
                  this.columnasMostrar.push(this.listaColumnas[k]);
                }
              }

              this.cargueColCA(["Agregar ventas"]);
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    metodoColor(row, col) {
      if (row.activo && col.name == "Activo") {
        return {
          "background-color": "#00FF00",
        };
      }
      if (!row.activo && col.name == "Activo") {
        return {
          "background-color": "#FF5555",
        };
      }
      return {};
    },

    openVenta(newVal, oldVal, row) {
      if (row._id == null) {
        this.$alert(
          "Debes recargar la página para realizar el registro de la venta, usuario sin ID desde base de datos"
        );
      } else {
        this.$refs.grid1.inputBoxBlur();
        this.$refs.grid.inputBoxBlur();
        this.idCli = row._id;
        this.nameCard = row.nombre;

        if (newVal.trim() == "Agregar ventas") {
          if (row["Lista negra"] === "LISTA NEGRA") {
            console.log("1.2");

            this.$confirm({
              auth: true,
              message:
                `Escribe "OK" para crear una venta del usuario ` +
                row.usuario +
                ` que se encuentra en LISTA NEGRA `,
              button: {
                no: "Cancelar",
                yes: "Aceptar",
              },
              callback: (confirm, password) => {
                if (confirm == true && password === "OK") {
                  this.addVenta = true;
                  this.linkDrive = row.linkDrive;
                } else {
                  this.$alert("Error: confirmación denegada");
                }
              },
            });
          } else {
            console.log("1.1");
            this.addVenta = true;
            this.linkDrive = row.linkDrive;
          }
        }
        if (newVal.trim() == "Agregar trabajo") {
          console.log("2");
          if (row["Lista negra"] === "LISTA NEGRA") {
            this.$confirm({
              auth: true,
              message:
                `Escribe "OK" para crear una venta de trabajos del usuario ` +
                row.usuario +
                ` que se encuentra en LISTA NEGRA `,
              button: {
                no: "Cancelar",
                yes: "Aceptar",
              },
              callback: (confirm, password) => {
                if (confirm == true && password === "OK") {
                  this.addVentaTrabajo = true;
                  this.linkDrive = row.linkDrive;
                } else {
                  this.$alert("Error: confirmación denegada");
                }
              },
            });
          } else {
            this.addVentaTrabajo = true;
            this.linkDrive = row.linkDrive;
          }
        }
      }
      return false;
    },

    newCli(a) {
      this.$refs.grid1.clearAllSelected();
      this.$refs.grid.clearAllSelected();
      if (a == "trabajo") {
        this.addCliT = true;
      } else {
        this.addCli = true;
      }
    },

    onSelect() {
      var la =
        this.$refs.grid.selectedCount == 0 ? this.$refs.grid1 : this.$refs.grid;
      this.onSelected = infoSelected(la, "_id");
      if (this.onSelected != null) {
        this.onSelected["mensaje"] =
          "el cliente con el usuario " +
          this.onSelected["field"]["Usuario"] +
          " con el nombre " +
          this.onSelected["field"]["Nombre"];
      }
      this.rigthClick = false;
    },

    eventosBtns(d) {
      console.log(d);
      this.onSelected = null;
      if (d == "crear cliente") {
        this.newCli("");
      }
      if (d == "crear cliente trabajo") {
        this.newCli("trabajo");
      }
    },
    // Eventos principales del ToolBarNav
    eventosClientesAdmin(d) {
      if (d == "refresh" || d == "edit") {
        this.initialize();
      }
      if (d == "editCli") {
        console.log(this.onSelected);
        if (this.onSelected.field["Usuario"].includes("UT")) {
          this.newCli("trabajo");
        } else {
          this.newCli("");
        }
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    eventosVenta(v) {
      if (v == "close") {
        this.addVenta = false;
        this.addVentaTrabajo = false;
      }
    },
    // el componente del btn de editar cliente
    eventosCliente(d) {
      console.log(d);
      if (d == "closeCli") {
        this.addCli = false;
      }
      if (this.addCli == true) {
        if (d._id == null) {
          this.jsondata.unshift({
            Nombre: d.nombre,
            Cédula: d.cc,
            Teléfono:
              d.telefono == ""
                ? null
                : d.telefono == null
                ? null
                : "+57" + d.telefono.replace(" ", ""),
            "Teléfono 2":
              d.telefono2 == ""
                ? null
                : d.telefono2 == null
                ? null
                : "+57" + d.telefono2.replace(" ", ""),
            Usuario: d.usuario,
            Contraseña: d.password,
            Correo: d.correo,
            Linea: d.linea,
            "Contraseña correo": d.passCorreo,
            Edad: d.edad,
            CEAD: d.cead,
            Carrera: d.carrera,
            Genero: d.genero,
            Activo: d.activo == true ? "Activo" : "Inactivo",
          });
        } else {
          this.jsondata[this.onSelected.selectedRow]["Nombre"] = d.nombre;
          this.jsondata[this.onSelected.selectedRow]["Cédula"] = d.cc;
          this.jsondata[this.onSelected.selectedRow]["Teléfono"] =
            d.telefono == ""
              ? null
              : d.telefono == null
              ? null
              : "+57" + d.telefono.replace(" ", "");
          this.jsondata[this.onSelected.selectedRow]["Teléfono 2"] =
            d.telefono2 == ""
              ? null
              : d.telefono2 == null
              ? null
              : "+57" + d.telefono2.replace(" ", "");
          this.jsondata[this.onSelected.selectedRow]["Usuario"] = d.usuario;
          this.jsondata[this.onSelected.selectedRow]["Contraseña"] = d.password;
          this.jsondata[this.onSelected.selectedRow]["Correo"] = d.correo;
          this.jsondata[this.onSelected.selectedRow]["Linea"] = d.linea;
          this.jsondata[this.onSelected.selectedRow]["Contraseña correo"] =
            d.passCorreo;
          this.jsondata[this.onSelected.selectedRow]["Edad"] = d.edad;
          this.jsondata[this.onSelected.selectedRow]["CEAD"] = d.cead;
          this.jsondata[this.onSelected.selectedRow]["Carrera"] = d.carrera;
          this.jsondata[this.onSelected.selectedRow]["Genero"] = d.genero;
          this.jsondata[this.onSelected.selectedRow]["Activo"] = d.activo;
        }
        this.mostrarTable = true;
        this.addCli = false;
      }
    },
    // el componente del btn de editar cliente trabajo
    eventosClienteT(d) {
      if (d == "closeCli") {
        this.addCliT = false;
      }
      if (this.addCliT == true) {
        if (d._id == null) {
          console.log(d);
          this.jsondata.unshift({
            Nombre: d.nombre,
            Teléfono:
              d.telefono == ""
                ? null
                : d.telefono == null
                ? null
                : "+57" + d.telefono.replace(" ", ""),
            "Teléfono 2":
              d.telefono2 == ""
                ? null
                : d.telefono2 == null
                ? null
                : "+57" + d.telefono2.replace(" ", ""),
            Correo: d.correo,
            Usuario: "UT-",
            Linea: d.linea,
            Activo: d.activo == true ? "Activo" : "Inactivo",
          });
        } else {
          this.jsondata[this.onSelected.selectedRow]["Nombre"] = d.nombre;
          this.jsondata[this.onSelected.selectedRow]["Cédula"] = d.cc;
          this.jsondata[this.onSelected.selectedRow]["Teléfono"] =
            d.telefono == ""
              ? null
              : d.telefono == null
              ? null
              : "+57" + d.telefono.replace(" ", "");
          this.jsondata[this.onSelected.selectedRow]["Teléfono 2"] =
            d.telefono2 == ""
              ? null
              : d.telefono2 == null
              ? null
              : "+57" + d.telefono2.replace(" ", "");
          this.jsondata[this.onSelected.selectedRow]["Correo"] = d.correo;
          this.jsondata[this.onSelected.selectedRow]["Linea"] = d.linea;
          this.jsondata[this.onSelected.selectedRow]["Activo"] = d.activo;
        }
        this.mostrarTable = true;
        this.addCliT = false;
      }
    },
  },
};
</script>
